<template>
  <div>
    <v-card>
      <v-card-title>Listado de especies</v-card-title>
      <v-card-text>
        <botonera 
          :botones="botones" 
          alinear="right"
          @crear="dialog = !dialog"
        ></botonera>
      </v-card-text>
    </v-card>
    <datatable 
      :cabeceras="especiesListHeaders" 
      :items="especiesListBody" 
      titulo="Listado de Especies" 
      label="Escriba Para buscar Especie" 
      icono="list" 
      color_icono="azul"
      :tachar="true"
      :acciones="['editar','eliminar','mostrar','ocultar']"
      @editar="editEspecie($event)"
      @eliminar="deleteEspecie($event)"
      @mostrar="mostrar($event)"
      @ocultar="ocultar($event)">
    </datatable>
    <!-- Modal Nuevo Especie -->
    <v-dialog
      v-model="dialog"
      width="800px"
      @click:outside = "closeModal"
    >
      <v-card>
        <v-card-title class="blue darken-1">{{ title }}</v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col
              class="align-center justify-space-between"
              cols="4"
            >
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Nombre"
                v-model="especie.nombre"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-barcode"
                placeholder="Abreviatura"
                v-model="especie.abreviatura"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-btn
                @click="closeModal"
              >Cerrar</v-btn>
              <v-btn
                color="primary"
                @click="saveEspecie"
              >Grabar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      title: 'Nueva Especie',
      color: 'green',
      snackbar: false,
      errors: [],
      validation_message: '',
      success: null,
      search: null,
      dialog: false,
      especieIndex: -1,
      especie: {
        id: 0,
        nombre: '',
        abreviatura: '',
      },
      especiesListHeaders: [
        {
          text: 'Id',
          align: 'center',
          sortable: true,
          value: 'id',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Nombre',
          align: 'center',
          sortable: true,
          value: 'nombre',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Abreviatura',
          align: 'center',
          sortable: true,
          value: 'abreviatura',
          class: "blue-grey darken-4 white-text"
        },
        {
          text: 'Acciones',
          align: 'center',
          sortable: false,
          value: 'actions',
          class: "blue-grey darken-4 white-text"
        },
      ],
      especiesListBody: [],
      botones:[
        {boton:'crear',tooltip:'Nueva Especie'},
        //{boton:'cargar',tooltip:'Carga Masiva'},
        //{boton:'eliminar',tooltip:'Borrar Todo'}
      ]
    }),
    mounted() {
      this.loadEspecies();
    },
    methods:{
      async loadEspecies(){
        this.mostrarLoading('Cargando listado de especies...')
        let url = `${this.base_url}especies`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.especiesListBody = response.data;
          this.ocultarLoading()
        }).catch((error)=>{
            console.log(error);
            this.ocultarLoading()
        });
      },
      async saveEspecie(){
        // validation
        this.errors = [];
        if(!this.especie.nombre){
          this.errors.push("Debe indicar nombre de la especie");
        }else if(this.especie.nombre.length > 20){
          this.errors.push("Tamaño máximo nombre: 20 caracteres");
        }
        if(!this.especie.abreviatura){
          this.errors.push("Debe indicar abreviatura de la especie");
        }else if(this.especie.abreviatura.length > 3){
          this.errors.push("Tamaño máximo abreviatura: 3 caracteres");
        }
        if(this.errors.length > 0){
          this.validation_message = this.errors.join();
          this.showSnackBar(true);
          return;
        }
        let url = `${this.base_url}especies`;
        let especieData = {
          'abreviatura': this.especie.abreviatura,
          'nombre': this.especie.nombre,
        };
        if(this.especie.id > 0){
          url = url + "/" + this.especie.id;
          await this.axios.put(url, especieData, this.headers).then((response)=>{
            let index = this.especiesListBody.map(especie => {
              return especie.id;
            }).indexOf(this.especie.id);
            this.especiesListBody.splice(index, 1, response.data);
            this.showSnackBar(false);
          }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
          });
        }else{
          await this.axios.post(url, especieData, this.headers).then((response)=>{
              this.especiesListBody.push(response.data);
              this.showSnackBar(false);
          }).catch((error)=>{
              this.errors = error.response.data;
              this.validation_message = this.errors.join();
              this.showSnackBar(true);
          });
        }
        this.cleanForm();
      },
      editEspecie(item) {
        this.especieIndex = this.especiesListBody.indexOf(item);
        this.especie = Object.assign({}, item);
        this.dialog = true;
        this.title = "Editar Especie";
      },
      async deleteEspecie(item) {
        this.$confirm('Desea borrar este especie?').then(res => {
          if(res){
            let url = `${this.base_url}especies/${item.id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              const index = this.especiesListBody.indexOf(item);
              this.especiesListBody.splice(index, 1);
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      closeModal(){
        this.cleanForm();
        this.dialog = false;
        this.error = null;
        this.title = "Nueva Especie";
      },
      cleanForm(){
        this.especie = {
          id: 0,
          nombre: '',
        };
        this.dialog = false;
      },
      async ocultar(item) {
        this.$confirm('Desea ocultar esta especie?').then(res => {
          if(res){
            let url = `${this.base_url}especies/ocultar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadEspecies();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async mostrar(item) {
        this.$confirm('Desea mostrar esta especie?').then(res => {
          if(res){
            let url = `${this.base_url}especies/mostrar/${item.id}`;
            this.axios.get(url, this.headers).then((response)=>{
              console.log(response);
              this.showSnackBar(false);
              this.loadEspecies();
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
      ...mapState(['loading', 'base_url', 'headers']),
      
    },
    components:{
      
    }
  }
</script>